import { forwardRef } from 'react';
export const ExpandIcon = forwardRef(({ ...props }, ref) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 19L5 19V14"
          stroke={props?.color ?? 'white'}
          stroke-width="2"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 5H19V10"
          stroke={props?.color ?? 'white'}
          stroke-width="2"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
});

export const ExpandIconNew = forwardRef(({ ...props }, ref) => {
  return (
    <>
      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.33337 9.33268H11.6667V5.99935H10.3334V7.99935H8.33337V9.33268ZM2.33337 5.99935H3.66671V3.99935H5.66671V2.66602H2.33337V5.99935ZM1.66671 11.3327C1.30004 11.3327 0.986152 11.2021 0.725041 10.941C0.46393 10.6799 0.333374 10.366 0.333374 9.99935V1.99935C0.333374 1.63268 0.46393 1.31879 0.725041 1.05768C0.986152 0.796571 1.30004 0.666016 1.66671 0.666016H12.3334C12.7 0.666016 13.0139 0.796571 13.275 1.05768C13.5362 1.31879 13.6667 1.63268 13.6667 1.99935V9.99935C13.6667 10.366 13.5362 10.6799 13.275 10.941C13.0139 11.2021 12.7 11.3327 12.3334 11.3327H1.66671ZM1.66671 9.99935H12.3334V1.99935H1.66671V9.99935Z" fill="#868585"/>
</svg>

    </>
  );
});
