import React, { memo } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { Spinner } from '../spinner';
import { Box, HStack } from '../layout';
import { Button } from '../button';
import { PauseIcon, PlayIcon, VolumeOff, VolumeUp } from '@mybridge/icons';
import { IconButton } from '../icon-button';

class ReelVideoPlayer_ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      playing: true,
      playingFirstTime: false,
      muted: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.playing !== this.state.playing) {
      return true;
    }
    if (
      !this.state.playingFirstTime &&
      nextProps.reelIndex === nextProps.activeIndex
    ) {
      return true;
    }

    if (nextState.muted !== this.state.muted) {
      return true;
    }
    if (nextState.ready !== this.state.ready) {
      return true;
    }
    if (JSON.stringify(nextProps.style) !== JSON.stringify(this.props.style)) {
      return true;
    }
    if (
      nextProps?.src?.substring?.(0, nextProps?.src?.indexOf('?')) !==
      this.props.src?.substring?.(0, this.props?.src?.indexOf('?'))
    ) {
      return true;
    }
    return false;
  }
  componentDidMount() {
    if (this?.props?.reelIndex === this.props.activeIndex) {
      this.setState({ playing: true });
    } else {
      this.setState({ playing: false });
    }
  }

  componentDidUpdate(prevState, prevProps) {
    if (
      prevState.reelIndex === prevState.activeIndex &&
      !this.state.playingFirstTime
    ) {
      this.setState({ playing: true, playingFirstTime: true });
    }
  }

  handlePlayPause = (e) => {
    this.setState({ playing: !this.state.playing });
  };
  onMute = (e) => {
    this.setState({ muted: !this.state.muted });
  };

  render() {
    return (
      <>
        <Box
          pos="relative"
          minH="300px"
          w="100%"
          {...(this.props.wrapperProps ?? {})}
        >
          <HStack
            pos="absolute"
            justifyContent="center"
            alignItems="center"
            opacity={this.state.ready ? 0 : 1}
            zIndex={this.state.ready ? -1 : 4}
            top={0}
            bottom={0}
            right={0}
            left={0}
            pointerEvents={this.state.ready ? 'none' : 'all'}
          >
            <Spinner size="lg" />
          </HStack>
          <Box
            style={{
              position: 'absolute',
              marginTop: '4%',
              right: '19%',
              zIndex: '9999',
            }}
          >
            {this.state.playing ? (
              <IconButton
                p={0}
                variant="transparent"
                onClick={this.handlePlayPause}
                icon={
                  <PauseIcon
                    width="20px"
                    zIndex="9999"
                    height="18px"
                    fill={'white'}
                  />
                }
              />
            ) : (
              <IconButton
                p={0}
                onClick={this.handlePlayPause}
                variant="transparent"
                icon={
                  <PlayIcon
                    width="22px"
                    zIndex="9999"
                    height="18px"
                    fill={'white'}
                  />
                }
              />
            )}
            {this.state.muted ? (
              <IconButton
                p={0}
                variant="transparent"
                onClick={this.onMute}
                icon={<VolumeOff />}
              />
            ) : (
              <IconButton
                p={0}
                onClick={this.onMute}
                variant="transparent"
                icon={<VolumeUp />}
              />
            )}
          </Box>
          <ReactPlayer
            ref={this.props.setPlayerRef()}
            onClick={this.handlePlayPause}
            url={this.props.src}
            playing={this.state.playing}
            muted={this.state.muted}
            onReady={() => {
              this.props?.onLoad?.();
              this.setState({ ready: true });
            }}
            onProgress={(e) => this.props?.getVideoDuration?.(e)}
            {...this.props}
            style={{
              cursor: 'pointer',
              position: 'relative',
              opacity: this.state.ready ? 1 : 0,
              ...(this.props.style ?? {}),
            }}
          />
          {/* </Box> */}
        </Box>
      </>
    );
  }
}

ReelVideoPlayer_.propTypes = {
  src: PropTypes.string.isRequired,
};

export const ReelVideoPlayer = memo(ReelVideoPlayer_);

export default ReelVideoPlayer;
