/* eslint-disable @nx/enforce-module-boundaries */
import { Box, HStack } from '@mybridge/ui';
import { Image } from '@mybridge/ui/image';
import { ReelVideoPlayer } from '@mybridge/ui/reelVideo-player';
import { useContext, useEffect, useRef, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PostBoxContext } from '../context';
import styles from './mediaReel-player.module.scss';

export const ModalreelDisplay = ({
  post,
  fullScreen = false,
  isReel = false,
  setPlayerRef,
  activeIndex,
  reelIndex,
}) => {
  const { media } = post;

  const [videos_, setVideos_] = useState([]);

  useEffect(() => {
    prepareMedia();
  }, [media]);

  const prepareMedia = async () => {
    const vids = [];
    for (let m of media ?? []) {
      const { file } = m ?? {};
      if (
        file?.toLowerCase?.()?.includes?.('.mp4') ||
        file?.toLowerCase?.()?.includes?.('.mov')
      ) {
        vids.push(file);
      }
    }
    setVideos_(vids);
  };

  return (
    <>
      {videos_?.length && isReel && (
        <ReelVideoPlayer
          reelIndex={reelIndex}
          setPlayerRef={setPlayerRef}
          activeIndex={activeIndex}
          isReel={true}
          wrapperProps={{ height: '100vh', width: '100%' }}
          src={videos_?.[0]}
          height="100%"
          width="100%"
          style={{
            backgroundColor: 'black',
            maxHeight: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
          className={styles.mediaPlayer}
        />
      )}
    </>
  );
};
