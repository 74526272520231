import API from 'packages/mybridgev4/store/common/api';
import { APIEndPoints } from "v4/store/common/endPoint";

export const fetchReels = async ( pageParam, page ) => {
    try {
      const obj = {
        url: APIEndPoints.Reels(pageParam, page, true),
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  };